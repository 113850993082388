<template>
  <div class="admin-view-response-team">
    <loading-screen :is-loading="isDeleting"></loading-screen>

    <!-- Is Loading -->
    <div v-if="isLoading" class="loader">
      <a-spin></a-spin>
    </div>
    <!-- / Is Loading -->

    <!-- Loaded -->
    <div v-if="!isLoading">
      <!-- Page Header -->
      <div class="page-header">
        <h1 class="page-title">
          <a-button
            type="default"
            style="display: inline; margin-right: 15px"
            @click.prevent="$router.push('/admin/responders/response-teams')"
            icon="left"
          ></a-button>

          <admin-org-indicator></admin-org-indicator>

          {{ title }}
        </h1>
        <div class="actions">
          <a-button
            class="button-margin-left btn-rounded"
            icon="edit"
            size="large"
            type="primary"
            @click.prevent="edit"
            >Edit</a-button
          >

          <a-popconfirm
            title="Are you sure?"
            ok-text="Yes"
            cancel-text="No"
            @confirm="attemptDelete"
          >
            <a-button
              class="button-margin-left btn-rounded"
              icon="delete"
              size="large"
              type="danger"
              >Delete</a-button
            >
          </a-popconfirm>
        </div>
      </div>
      <!-- / Page Header -->

      <a-tabs v-model="selectedTab">
        <a-tab-pane tab="Team Members" key="team-members">
          <response-team-members
            :tenant-id="tenantId"
            :org-id="selectedOrganisation.id"
            :response-team="responseTeam" 
            :all-presences="membership"
          ></response-team-members>
        </a-tab-pane>
      </a-tabs>
    </div>
    <!-- / Loaded -->
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import LoadingScreen from "../../../../components/LoadingScreen.vue";
import skills from "../../../../api/skills";
import ResponseTeamMembers from "./Show/ResponseTeamMembers.vue";
export default {
  components: { LoadingScreen, ResponseTeamMembers },
  data() {
    return {
      isDeleting: false,
      isLoading: false,
      responseTeam: null,

      selectedTab: "team-members",
    };
  },
  computed: {
    ...mapGetters("admin", {
      tenantId: "tenantId",
      selectedOrganisation: "selectedOrganisation",
      membership: "membership"
    }),

    title() {
      if (this.isLoading) {
        return "Loading...";
      }
      if (this.responseTeam && this.responseTeam.displayName) {
        return this.responseTeam.displayName;
      }
      return "Unknown";
    },

    responseTeamId() {
      return this.$route.params.id;
    },
  },
  created() {
    this.loadResponseTeam();
  },
  methods: {
    ...mapActions("admin", {
      loadSkillsSilently: "loadSkillsSilently",
    }),

    loadResponseTeam() {
      let vm = this;
      vm.isLoading = true;
      vm.responseTeam = null;
      skills
        .getSkill(this.tenantId, this.responseTeamId)
        .then((r) => {
          vm.responseTeam = r.data;
          vm.isLoading = false;
        })
        .catch((e) => {
          console.log(e);
          vm.$message.error("Cannot find response team");
          vm.$router.push("/admin/responders/response-teams");
        });
    },

    attemptDelete() {
      let vm = this;
      vm.isDeleting = true;
      skills
        .deleteSkill(this.tenantId, this.responseTeamId)
        .then(() => {
          vm.loadSkillsSilently();
          vm.$message.success("Response team deleted successfully");
          vm.isDeleting = false;
          vm.$router.push("/admin/responders/response-teams");
        })
        .catch((e) => {
          console.log(e);
          vm.$message.error("Error deleting response team");
          vm.isDeleting = false;
        });
    },

    edit() {
      this.$router.push(
        "/admin/responders/response-teams/" + this.responseTeamId + "/edit"
      );
    },
  },
};
</script>

<style scoped lang="scss">
.admin-view-response-team {
  padding-bottom: 30px;
}

.button-margin-left {
  margin-left: 10px;
}
</style>